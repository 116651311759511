import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useRef, useState } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FullBackground, Layout, PageContainer, SEO } from "components";
import { device } from "utils";
import { getCaptchaToken } from "../utils/captchaUtils";

const ContactUsPage = ({ location }) => {
  const [show, setShowContent] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [hasSubmissionResponse, setHasSubmissionResponse] = useState(false);

  const recaptchaRef = useRef(null);
  const title = "Contact Us";
  const description = "Have more questions or want to learn more?";

  useEffect(() => {
    let clickListener = null;
    let submitButton = null;

    const onFormSubmitted = ($form, data) => {
      setHasSubmissionResponse(true);
    };

    const onFormReady = ($form, e) => {
      const segmentInput = $form.querySelector("input[name='segment__c']");
      if (segmentInput) {
        segmentInput.value = "Enterprise"; // Defaulting to Enterprise for contacts coming in from this route.
      }

      clickListener = async (event) => {
        event.preventDefault();

        const token = await getCaptchaToken(recaptchaRef);
        if (!token) {
          return;
        }

        $form.submit();
      };

      submitButton = document.querySelector("[type='submit']");
      submitButton.addEventListener("click", clickListener, false);

      setFormReady(true);
    };

    const loadHubspot = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45776621",
          formId: "60af8d83-0d9b-4248-b1c4-55f516512549",
          sfdcCampaignId: "701Vs000006SiibIAC",
          target: "#hs-form-container",
          onFormReady,
          onFormSubmitted,
        });
      }
    };

    if (window.hbspt) {
      loadHubspot();
    } else {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        loadHubspot();
      });
    }

    return () => {
      if (clickListener && submitButton) {
        submitButton.removeEventListener("click", clickListener, false);
      }
    };
  }, []);

  useEffect(() => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-441213032/Bu2SCJmL7_IBEOjAsdIB",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  setTimeout(() => {
    setShowContent(true);
  }, 200);

  return (
    <FullBackground gradient="black">
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <S.FadeAnimation show={show}>
          <PageContainer>
            <Row>
              <Col
                sm={12}
                md={{ size: 8, offset: 2 }}
                lg={{ size: 6, offset: 3 }}
              >
                <S.ContactContainer>
                  {formReady && !hasSubmissionResponse && (
                    <S.Header>
                      Contact the business team to learn more or schedule a live
                      technology demonstration.
                    </S.Header>
                  )}
                  <S.HubspotForm id="hs-form-container"></S.HubspotForm>
                </S.ContactContainer>
                <S.Footer>
                  If you'd like to request employment verification for a
                  previous employee of ours, please email{" "}
                  <a
                    href="HR@emeraldcloudlab.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HR@emeraldcloudlab.com
                  </a>{" "}
                  directly for assistance.
                </S.Footer>
              </Col>
            </Row>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
              theme="dark"
            />
          </PageContainer>
        </S.FadeAnimation>
      </Layout>
    </FullBackground>
  );
};

export default ContactUsPage;

const S = {};

S.FadeAnimation = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 2s ease;
`;

S.ContactContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;

  @media ${device.md} {
    margin-top: 4.8rem;
  }
`;

S.Header = styled.div`
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
`;

S.HubspotForm = styled.div`
  color: ${({ theme }) => theme.white};

  .hs-form-field {
    margin-bottom: 1.7rem;
  }

  .actions > [type="submit"] {
    margin: 1.6rem 0;
    border-color: transparent;
    border-radius: 0;
    border: 0;
    font-size: 1.4rem;
    height: 3.2rem;
    /* padding: 0 2.1rem; */
    text-transform: uppercase;

    color: ${({ theme }) => theme.white};
    &:active {
      background-color: ${({ theme }) => theme.greenDarker};
      border-color: transparent;
    }
    &:hover {
      background-color: ${({ theme }) => theme.greenDarkerHover};
      border-color: transparent;
    }

    background-color: ${({ theme }) => theme.greenDarker};

    /* padding: 0; */
    margin: 1rem 0;

    @media all and ${device.sm} {
      width: 11.5rem;
    }
  }
`;

S.Footer = styled.div`
  color: rgb(177, 186, 194);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2rem;
  padding-bottom: 3rem;
  a {
    margin-left: 3px;
    font-size: 1.4rem;
    cursor: pointer;
    color: rgb(12, 189, 150);
    &:hover {
      text-decoration: underline;
    }
  }
`;
